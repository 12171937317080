<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Novo Fornecedor" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length" />
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <DataTable ref="dt" :value="fornecedores" v-model:selection="selectedProducts" dataKey="id" :paginator="true"
          :rows="50" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll">
          <template #header>
            <div class="
                            flex flex-column
                            md:flex-row md:justify-content-between md:align-items-center
                          ">
              <h5 class="m-0">Gereneciar Fornecedores</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="id" header="ID" :sortable="true" headerStyle="width:14%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="nome" header="Razão Social" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Razão Social</span>
              {{ slotProps.data.razao_social }}
            </template>
          </Column>
          <Column field="email" header="E-mail" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">E-mail</span>
              {{ slotProps.data.email }}
            </template>
          </Column>
          <Column field="email" header="Tipo de Faturamento" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Tipo de Faturamento</span>
              {{ this.descricao_tipos_faturamento[slotProps.data.tipo_faturamento] }}
            </template>
          </Column>


          <Column field="status" header="Status" :sortable="true" headerStyle="width:14%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Status</span>
              <span>{{ this.descricao_status[slotProps.data.status] }}</span>
            </template>
          </Column>

          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                @click="apagarFornecedorModal(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <Dialog v-model:visible="productDialog" :style="{ width: '450px' }" header="Gerenciar Fornecedor" :modal="true"
          class="p-fluid">

          <div class="field">
            <label for="name">Razão Social</label>
            <InputText id="bome" v-model.trim="fornecedor.razao_social" required="true" autofocus
              :class="{ 'p-invalid': submitted && !fornecedor.razao_social }" />
            <small class="p-invalid" v-if="submitted && !fornecedor.razao_social">Razão Social é obrigatório.</small>
          </div>
          <div class="field">
            <label for="email">E-mail</label>
            <input id="email" class="p-inputtext p-component p-filled" type="email" v-model.trim="fornecedor.email"
              required="true" autofocus :class="{ 'p-invalid': submitted && !fornecedor.email }" />
            <small class="p-invalid" v-if="submitted && !fornecedor.email">E-mail é obrigatório.</small>
          </div>
          <div class="field">
            <label for="name">CNPJ</label>
            <InputMask
              id="name"
              v-model.trim="fornecedor.cnpj"
              required="true"
              autofocus
              mask="99.999.999/9999-99"
              :class="{ 'p-invalid': submitted && !fornecedor.cnpj }"
            />
            <small class="p-invalid" v-if="submitted && !fornecedor.cnpj">CNPJ é obrigatório.</small>
          </div>
          <div class="field">
            <label for="Status" class="mb-3">Tipo de Faturamento</label>
            <Dropdown id="tipo_faturamento" v-model="fornecedor.tipo_faturamento" :options="tipos_faturamento"
              :class="{ 'p-invalid': submitted && !fornecedor.tipo_faturamento }" optionLabel="label" required="true"
              optionValue="value" placeholder="Selecione um tipo">
            </Dropdown>
            <small class="p-invalid" v-if="submitted && !fornecedor.tipo_faturamento">Tipo de Faturamento é
              obrigatório..</small>
          </div>

          <div class="field">
            <label for="Status" class="mb-3">Status</label>
            <Dropdown id="status" v-model="fornecedor.status" :options="statuses" optionLabel="label" optionValue="value"
              placeholder="Selecione um status"  :class="{ 'p-invalid': submitted && !fornecedor.status }" >
            </Dropdown>
            <small class="p-invalid" v-if="submitted && !fornecedor.status">Status é
              obrigatório..</small>
          </div>

          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarFornecedor" />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="usuario">Certeza que vai apagar: <b>{{ fornecedor.razao_social }}</b>?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm"
              @click="deleteProductDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarFornecedor" />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="product">Apagar usuários selecionados?</span>
          </div>
          <template #footer>
            <Button label="Sim" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
            <Button label="Não" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      fornecedores: [],
      fornecedor: [],
      id_empresa: "",
      empresas: [],
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      usuario: {},
      mostrar_usina: false,
      selectedProducts: null,
      filters: {},
      submitted: false,
      status: "",
      nivel_acesso: "",
      usinas: [],
      id_usina: "",
      lista_acesso: [
        { label: "Administrador", value: 1 },
        { label: "Adm. Fornecedor", value: 2 },
        { label: "Fornecedor", value: 3 },
      ],
      statuses: [
        { label: "Inativo", value: 0 },
        { label: "Ativo", value: 1 },
      ],
      tipos_faturamento: [

        { label: "Fatura", value: 1 },
        { label: "Contrato/SCI", value: 2 },
      ],
      descricao_tipos_faturamento: {
        1: "Fatura",
        2: "Contrato/SCI",
      },
      descricao_status: 
        { "0": "Inativo", "1": "Ativo" },
      
    };
  },
  productService: null,
  created() {
    this.initFilters();
  },
  mounted() {
    this.listarFornecedor();
    // this.listarEmpresa();
    // this.listarUsinas();
    //this.productService.getProducts().then((data) => (this.products = data));
  },
  methods: {
    showUsina() {
      if (this.usuario.id_tipo == 3) {
        this.mostrar_usina = true;
      }
      else {
        this.usuario.id_usina = 0
        this.mostrar_usina = false;
      }
    },

    listarFornecedor() {
      this.axios
        .post("/fornecedores/listar")
        .then((response) => {
          console.log(response);
          this.fornecedores = response.data.dados;
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      return;
    },
    openNew() {
      this.fornecedor = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    salvarFornecedor() {

      if (/^[^@]+@\w+(\.\w+)+\w$/.test(this.fornecedor.email) == false) {
        this.fornecedor.email = '';
        this.submitted = true;
        return false;

      }

      this.submitted = true;

      if (this.fornecedor.id) {
        this.fornecedor = { ...this.fornecedor };
        this.axios
          .post("fornecedores/editar", this.fornecedor)
          .then(() => {
            this.listarFornecedor();
            this.productDialog = false;
            this.usuario = {};
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        this.fornecedor = { ...this.fornecedor };
        this.axios
          .post("/fornecedores/adicionar", this.fornecedor)
          .then(() => {
            this.listarFornecedor();
            this.productDialog = false;
            this.usuario = {};
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },
    editProduct(usuario) {
      this.productDialog = true;
      this.fornecedor = { ...usuario };
    },
    apagarFornecedorModal(usuario) {
      this.usuario = usuario;
      this.deleteProductDialog = true;
    },
    listarEmpresa() {
      this.axios
        .post("/empresas/listar")
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["razao_social"] };
            options.push(option);
          });
          this.empresas = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    apagarFornecedor() {
      this.axios
        .post("/fornecedores/apagar", this.usuario)
        .then(() => {
          this.listarFornecedor();
          this.deleteProductDialog = false;
          this.usuario = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Product Deleted",
            life: 3000,
          });
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.fornecedores = this.fornecedores.filter(
        (val) => !this.selectedProducts.includes(val)
      );

      let obj = this.selectedProducts;
      Object.keys(obj).forEach((key) => {
        this.axios
          .post("/fornecedores/apagar", obj[key])
          .then(() => {
            this.listarFornecedor();
            this.deleteProductDialog = false;
            this.usuario = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Usário apagado com sucesso!s",
              life: 3000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      });

      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Sucesso",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
